import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { CREATE_UPDATE_USER } from '../../../../../../../libs/api/commands';
import { ROLES, GROUPS } from '../../../../../../../libs/api/queries';
import {
	CreateUpdateUserMutation,
	CreateUpdateUserMutationVariables,
	RolesQuery,
	RolesQueryVariables,
	GroupsQueryVariables,
	GroupsQuery,
} from '../../../../../../../libs/api/graphqlTypes';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import { ISelectItem } from '../../../../../../../libs/uiKit/select/types';
import { getSelectDefaultValue } from '../../../../../../../libs/utils/getSelectdefaultValue';

export type UserProps = {
	email?: string | undefined;
	fullName?: string | undefined;
	username?: string | undefined;
	phone?: string | undefined;
	groupId?: number | undefined;
	roleId?: number | undefined;
	isSuper?: boolean;
	showKv?: boolean;
	id?: number | undefined | null;
	isEnabled?: boolean;
	isEdit?: boolean;
	isDisabledEdit?: boolean;
	token?: string;
	type?: string;
};

type CreateUserForm = {
	full_name: string;
	username: string;
	phone?: string;
	email?: string;
	group?: ISelectItem;
	role?: ISelectItem;
	lead: boolean;
	show_kv: boolean;
	is_enabled: boolean;
	is_super?: boolean;
	remake_token?: boolean;
	reset_password?: boolean;
};

export type CreateUserProps = UserProps & IModalProps;

type CreateUserContext = IModalProps & {
	isLoadingUpdateUser: boolean;
	onFormSubmit: () => void;
	form: IForm;
	isEdit: boolean;
	isDisabledEdit: boolean;
	id: number;
	fullName: string;
};

const CreateUserContext = createContext<CreateUserContext>({} as CreateUserContext);

export const useCreateUserContext = (): CreateUserContext => useContext(CreateUserContext);

export const CreateUserContextProvider: React.FC<CreateUserProps> = props => {
	const { children, email, fullName, username, phone, groupId, showKv, isSuper: isUserSuper, roleId, id, isEnabled, isEdit, isDisabledEdit, onClose, isOpen, header, token, type } = props;
	const {
		user: { isSuper, isAdmin },
		routes: { error500 },
	} = useGlobalContext();

	const { setNotification } = useNotification();
	const formHook = useForm<CreateUserForm>({ mode: 'onBlur' });
	const [createUser, { loading: loadingCreateUser }] = useMutation<CreateUpdateUserMutation, CreateUpdateUserMutationVariables>(CREATE_UPDATE_USER, {
		refetchQueries: ['users', 'Users'],
		awaitRefetchQueries: true,
	});
	const { data: dataGroups, loading: loadingGroups } = useQuery<GroupsQuery, GroupsQueryVariables>(GROUPS, { onError: error => error500(error) });
	const { data: dataRoles, loading: loadingRoles } = useQuery<RolesQuery, RolesQueryVariables>(ROLES, { onError: error => error500(error) });

	const isCanEdit: boolean = isSuper || isAdmin;
	const isCanEditRoleGroup = isSuper;

	const isLeader: boolean = (dataGroups?.groups || []).find(group => group?.id === groupId)?.leader?.id === id;

	const groups: ISelectItem[] = (dataGroups?.groups || []).map(group => ({ label: group?.name || 'unknown', value: String(group?.id) }));
	const roles: ISelectItem[] = (dataRoles?.roles || []).map(role => ({ label: role?.name || 'unknown', value: String(role?.id) }));

	const onFormSubmit = formHook.handleSubmit(data => {
		createUser({
			variables: {
				id: id || null,
				input: {
					full_name: data.full_name,
					phone: data.phone,
					username: data.username,
					email: data.email,
					role_id: Number(data?.role?.value) || null,
					group_id: Number(data.group?.value) || null,
					lead: data.lead,
					is_super: !!data.is_super,
					is_enabled: data.is_enabled,
					show_kv: data.show_kv,
					remake_token: data.remake_token,
					reset_password: data.reset_password
				},
			},
		})
			.then((data: any) => {
				console.log(data);
				setNotification({
					type: 'success',
					text: data?.data?.user,
				});
				onClose();
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			isDisabled: isDisabledEdit,
			fields: [
				{
					field: {
						isDisabled: !isCanEdit,
						fieldType: 'input',
						fieldName: 'full_name',
						isRequired: true,
						grid: 6,
						defaultValue: fullName,
						placeholder: 'ФИО сотрудника',
						errorMessage: 'Введите ФИО',
					},
				},
				{
					field: {
						isDisabled: !isCanEdit,
						fieldType: 'input',
						fieldName: 'username',
						isRequired: true,
						grid: 6,
						defaultValue: username,
						placeholder: 'Логин сотрудника',
						errorMessage: 'Введите логин',
					},
				},
				{
					field: {
						isDisabled: !isCanEdit,
						fieldType: 'inputMask',
						fieldName: 'phone',
						defaultValue: phone,
						grid: 6,
						placeholder: 'Телефон сотрудника',
						mask: '+7 (999) 999-99-99',
						validationType: 'phone',
						errorMessage: 'Введите телефон',
					},
				},
				{
					field: {
						isDisabled: !isCanEdit,
						fieldType: 'input',
						fieldName: 'email',
						defaultValue: email,
						grid: 6,
						validationType: 'email',
						placeholder: 'E-mail сотрудника',
						errorMessage: 'Введите E-mail',
					},
				},
				{
					field: {
						isDisabled: !isCanEditRoleGroup,
						fieldType: 'select',
						fieldName: 'group',
						isLoading: loadingGroups,
						placeholder: 'Группа для учетной записи',
						defaultValue: getSelectDefaultValue(groupId, groups),
						isSearchAvailable: true,
						isClearable: true,
						items: groups,
						height: 15,
						grid: 6,
					},
				},
				{
					field: {
						isDisabled: !isCanEditRoleGroup,
						fieldType: 'select',
						fieldName: 'role',
						isLoading: loadingRoles,
						placeholder: 'Роль для учетной записи',
						isSearchAvailable: true,
						isClearable: true,
						defaultValue: getSelectDefaultValue(roleId, roles),
						items: roles,
						height: 12,
						grid: 6,
					},
				},
				{
					isHidden: !isSuper,
					field: {
						fieldType: 'checkbox',
						fieldName: 'lead',
						isDisabled: !isCanEdit,
						label: 'Сделать лидером в этой группе',
						defaultChecked: isLeader,
						grid: 6,
					},
				},
				{
					isHidden: !isSuper,
					field: {
						fieldType: 'checkbox',
						fieldName: 'show_kv',
						isDisabled: !isCanEdit,
						label: 'Отображение суммы КВ агента',
						defaultChecked: !!showKv,
						grid: 6,
					},
				},
				{
					field: {
						isDisabled: !isCanEdit,
						fieldType: 'checkbox',
						fieldName: 'is_enabled',
						label: 'Активен',
						defaultChecked: isEnabled,
						grid: 6,
					},
				},
				{
					isHidden: !isSuper,
					field: {
						isDisabled: !isCanEdit,
						fieldType: 'checkbox',
						fieldName: 'is_super',
						label: 'Сделать супер пользователем',
						defaultChecked: isUserSuper,
						grid: 6,
					},
				},
				{
					isHidden: !isSuper,
					field: {
						fieldType: 'input',
						fieldName: 'token',
						isDisabled: true,
						placeholder: 'Токен интеграции',
						defaultValue: token,
						grid: 9,
					},
				},
				{
					isHidden: !isSuper,
					field: {
						isDisabled: !isCanEdit,
						fieldType: 'checkbox',
						fieldName: 'remake_token',
						label: 'Пересоздать',
						defaultChecked: false,
						grid: 3,
					},
				},
				{
					isHidden: (type !== 'user'),
					field: {
						label: 'Сбросить пароль и отправить новый на почту',
						fieldType: 'checkbox',
						fieldName: 'reset_password',
						isDisabled: !isCanEdit,
					}
				}
			],
		}),
		[
			formHook,
			isDisabledEdit,
			fullName,
			username,
			phone,
			email,
			isCanEdit,
			loadingGroups,
			groupId,
			groups,
			isLeader,
			loadingRoles,
			roleId,
			roles,
			showKv,
			isEnabled,
			isSuper,
			isUserSuper,
		]
	);

	const value: CreateUserContext = React.useMemo(
		() => ({
			onClose,
			isOpen,
			header,
			isLoadingUpdateUser: loadingCreateUser,
			onFormSubmit,
			form,
			isEdit: !!isEdit,
			isDisabledEdit: !!isDisabledEdit || !isSuper && !isAdmin,
			id: id || 0,
			fullName: fullName || '',
		}),
		[loadingCreateUser, onFormSubmit, form, isEdit, isDisabledEdit, onClose, isOpen, header]
	);

	return <CreateUserContext.Provider value={value}>{children}</CreateUserContext.Provider>;
};
